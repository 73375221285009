<template>
  <div class="mx-0 flex flex-wrap">
    <templates-picker
      v-if="!selectedWaTemplate"
      :inbox-id="inboxId"
      @onSelect="pickTemplate"
    />
    <template-parser
      v-else
      :template="selectedWaTemplate"
      :phone="phone"
      @resetTemplate="onResetTemplate"
      @sendMessage="onSendMessage"
    />
  </div>
</template>

<script>
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser.vue';
export default {
  components: {
    TemplatesPicker,
    TemplateParser,
  },
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },
    show: {
      type: Boolean,
      default: true,
    },
    phone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedWaTemplate: null,
    };
  },
  methods: {
    pickTemplate(template) {
      this.$emit('pickTemplate', true);
      this.selectedWaTemplate = template;
    },
    onResetTemplate() {
      this.$emit('pickTemplate', false);
      this.selectedWaTemplate = null;
    },
    onSendMessage(message) {
      console.log(`Message template: ${JSON.stringify(message)}`);
      console.log(`phone: ${this.phone}`);
      const sendMessageStructure = {
        destinations: [
          {
            to: {
              phoneNumber: '',
            },
          },
        ],
        whatsApp: {
          templateName: '',
          templateData: [],
          language: '',
        },
      };
      sendMessageStructure.destinations[0].to.phoneNumber =
        message.phone ?? this.phone;
      sendMessageStructure.whatsApp.templateName = message.templateParams.name;
      sendMessageStructure.whatsApp.language = message.templateParams.language;
      sendMessageStructure.whatsApp.templateData = Object.values(
        message.templateParams.processed_params
      );
      console.log(
        `sendMessageStructure: ${JSON.stringify(sendMessageStructure)}`
      );

      this.$emit('on-send', sendMessageStructure);
    },
    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>

<style></style>
